import React from "react"
import Contact_Form from "./contact_form"
import SocialIcons from "./socialIcons"
import Attribution from "./attribution"

const Footer = () => (
  // <!-- Footer -->
  <footer class="footer">
    <Contact_Form />
    <SocialIcons />
    <Attribution />
  </footer>
  // <!-- End Footer -->
)

export default Footer
