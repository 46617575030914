import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Header_Images from "./header_images"

/* <Link to="/">{siteTitle}</Link> */

// <!-- Header -->
const Header = siteTitle => (
  <header className="header">
    <div className=" header__text-box">
      <h1 className="heading-primary">
        <span className="heading-primary--main">Hi, I'm Mac Goldman</span>
        <span className="heading-primary--sub">
          Web Developer&#x1F4BB; | Data Alchemist&#x2728; | Coffee
          Addict&#x2615;
        </span>
      </h1>
      <Header_Images />
      <br />
      <a href="#section-projects" className="btn btn--white btn-animated">
        Check out my skills
      </a>
    </div>
  </header>
)
// <!-- End Header -->

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
