import React from "react"

const SocialIcons = () => (
  <div class="icons">
    <a
      href="https://github.com/Mac-Mann"
      target="_blank"
      rel="noreferrer"
      class="icon icon--github"
      aria-label="button 10"
    >
      <i class="fab fa-github"></i>
    </a>

    <a
      href="https://www.linkedin.com/in/macgoldman/"
      target="_blank"
      rel="noreferrer"
      class="icon icon--linkedin"
      aria-label="button 11"
    >
      <i class="fab fa-linkedin"></i>
    </a>
  </div>
)

export default SocialIcons
