import React from "react"

const Contact_Form = () => (
  // <!-- Contact Form -->
  <article class="article-contact-form">
    <form
      class="contact-form"
      method="POST"
      action="https://formspree.io/moqdqvjk"
      id="contact-form"
    >
      <div class="contact-form__fieldset">
        <div class="row">
          <h2 class="heading-secondary heading-secondary--white">
            Questions or Comments?
          </h2>
          <p class="heading-tertiary">
            Please feel free to reach out to me here. I promise to respond back
            within the next 24 hours. I look forward to hearing from you soon!
          </p>
          <div class="col-1-of-2 contact-form__field">
            <label for="name">Name</label>
            <input
              type="text"
              name="name"
              value=""
              tabindex="0"
              placeholder="John Doe"
              id="name"
              required
            />
          </div>

          <div class="col-1-of-2 contact-form__field">
            <label for="email" class="u-left-text">
              Email
            </label>
            <input
              type="text"
              name="name"
              value=""
              tabindex="0"
              placeholder="john.doe@gmail.com"
              id="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              title="example@example.com"
            />
          </div>
        </div>

        <div class="row">
          <div class="contact-form__field col-1">
            <label for="message">Message</label>
            <textarea
              name="message"
              id="message"
              tabindex="0"
              placeholder="Hi Mac, I would love to work with you!"
              maxlength="3000"
              required
            ></textarea>
          </div>
        </div>

        <div class="btn btn--white btn--animated u-margin-top-small">
          Send
          <input type="submit" id="Form-Submit" tabindex="0" />
        </div>
      </div>
    </form>
  </article>
)

export default Contact_Form
