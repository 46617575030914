import React from "react"

const Favicon = () => (
  // !-- <favicon> -->
  <link
    rel="shortcut icon"
    type="image/ico"
    href="{{ site.baseurl }}/assets/img/favicon.ico"
  />
)

export default Favicon
