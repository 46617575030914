import React from "react"
import Favicon from "./favicon"

// <!-- Head -->
const Head = () => (
  <head>
    <meta charset="UTF-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1"
      id="viewport-meta"
    />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <Favicon />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900"
      defer
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
      defer
      integrity="sha256-46qynGAkLSFpVbEBog43gvNhfrOj+BmwXdxFgVK/Kvc="
      crossorigin="anonymous"
    />
    <title>
      Mac Goldman | Web Developer | Data Alchemist | Coffee Addict &#x2615;
    </title>
  </head>
)
// <!-- Head -->

export default Head
