import React from "react"

const Attribution = () => (
  <p class="paragraph">
    <span class="paragraph__attribution">
      {" "}
      Design: Inspiration from HTML5 UP (Directive), but coded completely by
      hand. © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </span>
    <span class="paragraph__info">
      {" "}
      All borrowed resources are properly attributed in source code. Background
      Image: Unsplash{" "}
    </span>
  </p>
)

export default Attribution
