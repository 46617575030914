/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Head from "./head"
import Nav from "./nav"
import Header from "./header"
import Footer from "./footer"
import "../sass/styles.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <html lang="en">
      <Head />
      <body>
        <Header siteTitle={data.site.siteMetadata.title} />
        {/* <Nav /> */}
        <main>{children}</main>
        <Footer />
      </body>
    </html>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
